import { Axios } from '../axios/axiosInstance';
import { useQuery } from '@tanstack/react-query';
import { ProfileFormPayload } from 'types/user-types';

export const useGetUserApi = () => {
  const getUser = async () => {
    const { data } = await Axios.get(`/accounts/get_user`);
    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['get-user'],
    queryFn: getUser,
  });

  return { data, isLoading };

};

export const createProfileApi = async (payload: ProfileFormPayload) => {
  const { data } = await Axios.post(`/profiles`, payload);
  return data;
};

export const updateProfileApi = async (id: string, payload: ProfileFormPayload) => {
  const { data } = await Axios.put(`/profiles/${id}`, payload);
  return data;
};

export const useGetIndustriespi = () => {
  const fetchIndustries = async () => {
    const { data } = await Axios.get(`/industry`, { params: { length: Number.MAX_SAFE_INTEGER } });
    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['fetch-industries'],
    queryFn: fetchIndustries,
  });

  return { data, isLoading };
};

export const useProfileApi = () => {
  const getProfile = async () => {
    const { data } = await Axios.get(`/get_profile_by_user`);
    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['get-profile'],
    queryFn: getProfile,
  });

  return { data, isLoading };
};
