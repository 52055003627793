interface Option {
  value: any;
  label: string;
}

export const selectOptionsA = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'none', label: 'Non Binary' },
];
export const selectOptionsB: Option[] = [
  { value: 18, label: '18 - 25' },
  { value: 25, label: '25 - 30' },
  { value: 30, label: '30 - Above' }
];
export const selectOptionsC: Option[] = [
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'france', label: 'France' },
  { value: 'others', label: 'Others' }
];
export const selectOptionsD: Option[] = [
  { value: 'english', label: 'English' },
  { value: 'french', label: 'French' },
  { value: 'others', label: 'Others' }
];
export const selectOptionsE: Option[] = [
  { value: 'lagos', label: 'Lagos State' },
  { value: 'ogun', label: 'Ogun State' },
  { value: 'abia', label: 'Abia State' }
];
export const selectOptionsF: Option[] = [
  { value: 'option1', label: 'Marketing' },
];

export const checkboxOptionsA = [
  { label: 'Broad Band', value: 'broadband' },
  { label: 'Mobile Data', value: 'data' },
];
export const checkboxOptionsB = [
  { label: 'Windows', value: 'windows' },
  { label: 'MacOS', value: 'macos' },
  { label: 'Linux', value: 'linux' },
];
export const checkboxOptionsC = [
  { label: 'Android tablet', value: 'andriodtab' },
  { label: 'iPad', value: 'ipad' },
];
export const checkboxOptionsD = [
  { label: 'Android', value: 'andriod' },
  { label: 'iPhone', value: 'iphone' },
];
export const checkboxOptionsE = [
  { label: 'Social Media', value: 'social_media' },
  { label: 'Online Shopping', value: 'online_shopping' },
  { label: 'Work', value: 'work' },
  { label: 'Others', value: 'others' },
];
export const checkboxOptionsF = [
  { label: 'Visual', value: 'visual' },
  { label: 'Hearing', value: 'hearing' },
  { label: 'None', value: 'none' }
];
export const radioOptionsA = [
  { label: 'Daily', value: 'daily', name: 'daily' },
  { label: 'Weekly', value: 'weekly', name: 'weekly' },
  { label: 'Monthly', value: 'monthly', name: 'monthly' },
];
export const radioOptionsB = [
  { label: 'Windows', value: 'windows', name: 'windows' },
  { label: 'iOS', value: 'ios', name: 'ios' },
  { label: 'Linux', value: 'linux', name: 'linux' },
];
